import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import {Colors} from '../../../constants/colors';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  label: {
    color: Colors.primaryBlack,
    fontSize: '1.4rem',
    lineHeight: '1.1rem',
    // transform: 'translate(2rem, 1.5rem) scale(1)'
  },
  labelShrink: {
    fontSize: '1rem',
    lineHeight: 1
  // transform:'translate(1.3rem, -0.55rem) scale(0.55) !important'
  },
  inputRoot: {
    color: Colors.primaryBlack,
    outline: 'none',
    borderRadius: '0.6rem',
    // height: '2.3rem'
    // padding: '2rem 1.4rem'
  },
  input: {
   // height: '1.5rem'
  },
  notchedOutline: {
    borderWidth: '1px !important',
    borderColor: Colors.primary
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      width: '3rem',
      height: '3rem'
    }
  }
}))

export default function CustomInput(props) {
  const classes = useStyles();
  const { onIconClick, ...rest } = props
  return (
    <TextField
      {...rest}
      variant="outlined"
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.labelShrink
        },
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          notchedOutline: classes.notchedOutline
        },
        endAdornment:
          props.icon && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onIconClick}
              edge="end"
              className={classes.iconButton}
            >
              {props.icon}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
