import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SelectedIcon from '../../../assets/icons/checkbox_selected.svg';
import UnselectedIcon from '../../../assets/icons/checkbox.svg';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

const useStyles = makeStyles({
  disabledIcon: {
    opacity: '.5',
  },
  label: {
    margin: '0 1.2rem 0 0',
    padding: 0
  }
});

const CustomCheckbox = ({ onChange, name, checked, disabled, indeterminate }) => {
  const classes = useStyles();

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      name={name}
      disabled={disabled}
      color="primary"
      indeterminate={indeterminate}
      classes={{
        root: classes.label
      }}
      checkedIcon={
        <SelectedIcon className={disabled ? classes.disabledIcon : ''} />
      }
      icon={<UnselectedIcon className={disabled ? classes.disabledIcon : ''} />}
    />
  );
};

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool
};

export default CustomCheckbox;
