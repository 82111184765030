import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SmallImageSelector from '../../components/SmallImageSelector';
import CTATextField from '../../components/CTATextField';
import { Colors } from '../../constants/colors';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import Moment from 'moment';
import CustomInput from '../UI/Input/Input';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: Colors.white,
    borderRadius: '0.8rem',
    boxShadow: '0 0.2rem 0.5rem 0 rgba(182,194,213,0.5)',
    paddingBottom: '2rem'
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2.7rem 2.7rem 1rem 2.7rem',
    width: '100%',
    backgroundColor: Colors.aliceblue,
    borderTopRightRadius: '.8rem',
    borderTopLeftRadius: '.8rem',
  },
  headerText: {
    fontSize: '2.2rem',
    lineHeight: '3rem',
    color: Colors.primaryBlack,
  },
  form: {
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem',
    marginTop: '2.1rem'
  },
  formField: {
    marginBottom: '1.2rem'
  },
  textField: {
    marginTop: 16,
    backgroundColor: Colors.white,
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    }
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    backgroundColor: Colors.white,
    [`& fieldset`]: {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    }
  },
  label: {
    marginTop: 10
  },
  radioLabel: {
    color: Colors.primaryBlack
  },
  actionTitle: {
    marginTop: '2rem',
    color: Colors.primaryBlack
  },
  datePicker: {
    width: '100%',
    borderWidth: 1,
    fontSize: '1.2rem',
    padding: '1.2rem',
    color: Colors.primaryBlack,
    borderStyle: 'solid',
    borderColor: Colors.primary,
    borderRadius: '0.6rem'
  },
  datePickerLabel: {
    color: Colors.primaryBlack,
    marginLeft: '0.3rem'
  }
}));

function ImageBuilder(props) {
  const classes = useStyles();
  const [title, setTitle] = useState(props.item.title);
  const [cta, setCta] = useState(props.item.cta.name);
  const [redirect, setRedirect] = useState(props.item.cta.link);
  const [actionType, setActionType] = useState(props.item.cta.type);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);

  useEffect(() => {
    if (props.item.title !== title) {
      setTitle(props.item.title);
      setCta(props.item.cta.name);
      setRedirect(props.item.cta.link);
      setActionType(props.item.cta.type);
    }
    if (props.item.cta.type === 'event') {
      setEventStartDate(props.item.cta.startDate);
      setEventEndDate(props.item.cta.endDate);
    }
  }, [props.item, title]);

  const handleImageSelect = (url, data) => {
    var object = props.item;
    object.image = {
      url: url,
      type: data.type,
      info: data.info
    };
    props.onUpdate(object);
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
    var object = props.item;
    object.title = e.target.value;
    props.onUpdate(object);
  };

  const handleRedirect = e => {
    setRedirect(e.target.value);
    var object = props.item;
    object.cta.link = e.target.value;
    props.onUpdate(object);
  };

  const handleCta = value => {
    if (value.length < 20) {
      setCta(value);
      var object = props.item;
      object.cta.name = value;
      props.onUpdate(object);
    }
  };

  const handleActionRadio = e => {
    setActionType(e.target.value);
    var object = props.item;
    object.cta.type = e.target.value;
    props.onUpdate(object);
  };

  const handleStartDate = date => {
    var object = props.item;
    if (new Moment(date).isAfter(new Moment(eventEndDate))) {
      setEventEndDate(date[0]);
      object.cta.endDate = date[0];
    }
    setEventStartDate(date[0]);
    object.cta.startDate = date[0];
    props.onUpdate(object);
  };

  const handleEndDate = date => {
    setEventEndDate(date[0]);
    var object = props.item;
    object.cta.endDate = date[0];
    props.onUpdate(object);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.head}>
        <p className={classes.headerText}>Card {props.cardId}</p>
        <IconButton
          disabled={props.editingMode}
          aria-label="delete"
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <SmallImageSelector handleSelect={handleImageSelect} />
      <div className={classes.form}>
        <div className={classes.formField}>
          <CustomInput
            value={title}
            label="Title"
            fullWidth={true}
            onChange={handleTitleChange}
          />
        </div>

        <Typography variant="h6" className={classes.actionTitle}>
          Action
        </Typography>
        <RadioGroup onChange={handleActionRadio} value="redirect" row>
          <FormControlLabel
            value="redirect"
            control={<Radio disabled={props.editingMode} color="primary" />}
            label="Redirect"
            className={classes.radioLabel}
          />
          <FormControlLabel
            value="event"
            control={<Radio disabled={props.editingMode} color="primary" />}
            label="Event"
            className={classes.radioLabel}
            disabled={true}
          />
          <FormControlLabel
            value="none"
            control={<Radio disabled={props.editingMode} color="primary" />}
            label="None"
            className={classes.radioLabel}
            disabled={true}
          />
        </RadioGroup>
        <div>
          <div className={classes.formField}>
            <CTATextField onChange={handleCta} value={cta} />
          </div>
          <div className={classes.formField}>
            <CustomInput
              value={redirect}
              label="Redirect URL"
              fullWidth={true}
              onChange={handleRedirect}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
}

ImageBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
};

export default ImageBuilder;
