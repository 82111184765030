import React, { useState, useEffect, useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import { Colors } from '../../constants/colors';
import { useDropzone } from 'react-dropzone';
import { getCroppedImg } from './cropImage';
import { getUnsplash} from '../../state/actions/mainActions';
import CustomInput from '../UI/Input/Input';
import SearchIcon from '../../assets/icons/search_icon.svg';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  tabs: {
    backgroundColor: Colors.aliceblue
  },
  tab: {
    minWidth: 'auto',
    textTransform: 'none',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: Colors.primaryBlack,
    opacity: 0.5
  },
  tabSelected: {
    opacity: 1,
    color: `${Colors.primaryBlack} !important`,
  },
  selectedImage: {
    width: '65%',
    height: 200,
    textAlign: 'center',
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderWidth: 0.5,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 10
  },
  photoContainer: {
    height: '22.5rem',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem'
  },
  uploadBox: {
    height: 200,
    width: '100%',
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem',
    marginTop: 30
  },
  photoHolder: {
    height: '10.5rem',
    width: '10.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '0.6rem',
    marginRight: '1.4rem',
    marginBottom: '1.4rem',
    '&:nth-child(3n)': {
      marginRight: 0
    }
  },
  photo: {
    // height: '10rem',
    // width: '10rem',
    transition: '.3s ease-in-out',
    borderRadius: '0.8rem',
    '&:hover': {
      opacity: 0.5
    }
  },
  noPhotoIcon: {
    color: Colors.darkGray,
    opacity: 0.3,
    width: '6rem',
    height: '6rem'
  },
  searchRow: {
    display: 'flex',
    marginBottom: '2.1rem',
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem',
    marginTop: '3.3rem'
  },
  searchField: {
    backgroundColor: Colors.white,
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    }
  },
  selectedPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 10
  },
  dragArea: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    borderRadius: 10,
    borderStyle: 'dashed',
    borderColor: '#9E9E9E',
    borderWidth: 1
  },
  cropBox: {
    width: '100%',
    height: 300,
    position: 'relative',
    borderBottom: '1 solid black'
  },
  cropper: {
    position: ' absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 40
  },
  slider: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 4,
    paddingBottom: 4
  }
});

function SmallImageSelector(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imageType, setImageType] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const { unsplashImages} = useSelector((state) => state.mainReducer);

  const searchUnsplash = (searchValue) => {
    dispatch(getUnsplash({ query: searchValue }))
  };

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onload = e => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  });

  useEffect(() => {
    setSelectedImage('');
  }, [props.clear]);

  const handleImageButton = (event, value) => {
    setImageType(value);
  };

  const handleSearchFieldText = e => {
    setSearch(e.target.value);
  };

  const handleSearchButton = () => {
    if (search !== null && search !== '') {
      switch (imageType) {
        case 0:
          // Search Unsplash
          searchUnsplash(search);
          break;
      }
    }
  };

  const handleUnsplashImage = image => {
    props.handleSelect(image.urls.regular, {
      type: 'unsplash',
      info: {
        photographer: image.user.name,
        link: image.user.links.html
      }
    });
  };

  const handleGiphyImage = url => {
    props.handleSelect(url, {
      type: 'giphy',
      info: {}
    });
  };

  const showImage = async () => {
    var croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, 0);
    props.handleSelect(croppedImage, {
      type: 'custom',
      info: {}
    });
  };

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={imageType}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleImageButton}
        variant="fullWidth"
        centered
      >
        <Tab
          label="Unsplash"
          classes={{
            root: classes.tab,
            selected: classes.tabSelected
          }}
        />
        <Tab
          label="GIPHY"
          classes={{
            root: classes.tab,
            selected: classes.tabSelected
          }}
          disabled={true}
        />
        <Tab
          label="Upload"
          classes={{
            root: classes.tab,
            selected: classes.tabSelected
          }}
          disabled={true}
        />
      </Tabs>
      {imageType !== 2 ? (
        <div className={classes.searchRow}>
          <CustomInput
            value={search}
            label="Search Images"
            fullWidth={true}
            icon={<SearchIcon />}
            onChange={handleSearchFieldText}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                handleSearchButton();
              }
            }}
          />
        </div>
      ) : (
        <div style={{ height: 50 }} />
      )}
      {imageType === 0 ? (
        <div className={classes.photoContainer}>
          {unsplashImages ?
            unsplashImages.map((image, index) => (
              <div className={classes.photoHolder}
                 key={index}
                 role="button"
                 tabIndex={0}
                 onClick={() => handleUnsplashImage(image)}
                 style={{
                  backgroundImage: `url('${image.urls.regular}')`
                }}
              />
            ))
            : null}
        </div>
      ) : (
        <div className={classes.cropBox}>
          <div className={classes.cropper}>
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              minZoom={0.4}
              aspect={16 / 9}
              restrictPosition={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.slider}>
            <div style={{ flexGrow: 1, paddingRight: 24 }}>
              <Slider
                value={zoom}
                min={0.4}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                classes={{ container: 'slider' }}
              />
            </div>
            <Button onClick={showImage} variant="contained" color="primary">
              Save
            </Button>
            <IconButton
              aria-label="delete"
              onClick={() => setSelectedImage('')}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default SmallImageSelector;
