import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SmallImageSelector from '../../components/SmallImageSelector';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Colors } from '../../constants/colors';
import CTATextField from '../../components/CTATextField';
import Moment from 'moment';
import ReactQuill from 'react-quill';
import { quillStyles } from '../../constants/styles';

import 'flatpickr/dist/themes/airbnb.css';
import 'react-quill/dist/quill.snow.css';
import Flatpickr from 'react-flatpickr';
import CustomInput from '../UI/Input/Input';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: Colors.white,
    borderRadius: '0.8rem',
    boxShadow: '0 0.2rem 0.5rem 0 rgba(182,194,213,0.5)',
    paddingBottom: '2rem'
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2.7rem 2.7rem 1rem 2.7rem',
    width: '100%',
    backgroundColor: Colors.aliceblue,
    borderTopRightRadius: '.8rem',
    borderTopLeftRadius: '.8rem',
  },
  headerText: {
    fontSize: '2.2rem',
    lineHeight: '3rem',
    color: Colors.primaryBlack,
  },
  form: {
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem',
    marginTop: '2.1rem'
  },
  formField: {
    marginBottom: '1.2rem'
  },
  textField: {
    marginTop: 16,
    backgroundColor: Colors.white,
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    }
  },
  formControl: {
    width: '100%',
    marginTop: 20,
    backgroundColor: Colors.white,
    [`& fieldset`]: {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    }
  },
  actionTitle: {
    marginTop: '2rem',
    color: Colors.primaryBlack
  },
  radioLabel: {
    color: Colors.primaryBlack
  },
  quill: {
    height: '12.4rem',
    marginBottom: '10rem',
    ...quillStyles
  },
  datePicker: {
    width: '100%',
    borderWidth: 1,
    fontSize: '1.2rem',
    padding: '1.2rem',
    color: Colors.primaryBlack,
    borderStyle: 'solid',
    borderColor: Colors.primary,
    borderRadius: '0.6rem'
  },
  datePickerLabel: {
    color: Colors.primaryBlack,
    marginLeft: '0.3rem'
  }
}));

// const ctaOptions = ['Learn More', 'Contact Us'];

function InfoBuilder(props) {
  const classes = useStyles();
  const [title, setTitle] = useState(props.item.title);
  const [description, setDescription] = useState(props.item.description);
  const [ctaOne, setCtaOne] = useState(props.item.ctaOne.name);
  const [redirectOne, setRedirectOne] = useState(props.item.ctaOne.link);

  useEffect(() => {
    setTitle(props.item.title);
    setDescription(props.item.description);
    setCtaOne(props.item.ctaOne.name);
    setRedirectOne(props.item.ctaOne.link);
  }, [props.item, title]);

  const handleImageSelect = (url, data) => {
    var object = props.item;
    object.image = {
      url: url,
      type: data.type,
      info: data.info
    };
    props.onUpdate(object);
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
    var object = props.item;
    object.title = e.target.value;
    props.onUpdate(object);
  };

  const handleDescriptionChange = (value, delta, source, editor) => {
    setDescription(editor.getContents());
    var object = props.item;
    object.description = editor.getContents();
    props.onUpdate(object);
  };

  const handleCtaOne = value => {
    if (value.length < 18) {
      setCtaOne(value);
      var object = props.item;
      object.ctaOne.name = value;
      props.onUpdate(object);
    }
  };

  const handleRedirectOne = e => {
    setRedirectOne(e.target.value);
    var object = props.item;
    object.ctaOne.link = e.target.value;
    props.onUpdate(object);
  };

  const handleActionOneRadio = e => {
    var object = props.item;
    object.ctaOne.type = e.target.value;
    props.onUpdate(object);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }]
    ]
  };

  const formats = [
    'bold',
    'italic',
    'list',
    'bullet',
    'indent',
    'underline',
    'align',
    'header'
  ];

  return (
    <Paper className={classes.root}>
      <div className={classes.head}>
        <p className={classes.headerText}>Card {props.cardId}</p>
        <IconButton
          disabled={props.editingMode}
          aria-label="delete"
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <SmallImageSelector handleSelect={handleImageSelect} />
      <div className={classes.form}>
        <div className={classes.formField}>
          <CustomInput
            value={title}
            label="Title"
            fullWidth={true}
            onChange={handleTitleChange}
          />
        </div>
       <div className={classes.formField}>
         <ReactQuill
           value={description}
           theme="snow"
           modules={modules}
           formats={formats}
           placeholder="Description"
           onChange={handleDescriptionChange}
           preserveWhitespace={true}
           className={classes.quill}
         />
       </div>
        <Typography variant="h6" className={classes.actionTitle}>
          Action
        </Typography>
        <RadioGroup onChange={handleActionOneRadio} value="redirect" row>
          <FormControlLabel
            value="redirect"
            control={<Radio color="primary" disabled={props.editingMode} />}
            label="Redirect"
            className={classes.radioLabel}
          />
          <FormControlLabel
            value="event"
            control={<Radio color="primary" disabled={props.editingMode} />}
            label="Event"
            className={classes.radioLabel}
            disabled={true}
          />
          <FormControlLabel
            value="none"
            control={<Radio color="primary" disabled={props.editingMode} />}
            label="None"
            className={classes.radioLabel}
            disabled={true}
          />
        </RadioGroup>
        <div>
          <div className={classes.formField}>
            <CTATextField onChange={handleCtaOne} value={ctaOne} />
          </div>
          <div className={classes.formField}>
            <CustomInput
              value={redirectOne}
              label="Redirect URL"
              fullWidth={true}
              onChange={handleRedirectOne}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
}

InfoBuilder.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cardId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  editingMode: PropTypes.bool.isRequired
};

export default InfoBuilder;
