import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../constants/colors';
import { useDrop } from 'react-dnd';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 200
  },
  icon: {
    width: 60,
    height: 60,
    marginBottom: 4
  }
}));

function DragZone(props) {
  const classes = useStyles();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['card'],
    drop: (props) => (
      { name: 'Dropzone', row: props.row }
    ),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;
  // const notAllowed = !canDrop && isOver;
  let borderColor = null;
  let height = '1.5rem';
  if (isActive) {
    borderColor = Colors.success;
    height = 100;
  }

  return (
    <div
      ref={drop}
      className={classes.root}
      style={{ borderColor, height }}
    />
  );
}

DragZone.propTypes = {
  row: PropTypes.number,
  column: PropTypes.number,
  restricted: PropTypes.bool
};

export default DragZone;
