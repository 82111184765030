import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Iphone from '../../assets/images/iphone-with-notch.svg';
import Menu from '../../assets/images/menu.svg';
import Dropzone from './Dropzone';
import DragZone from './DragZone';
import InfoCard from './InfoCard';
import ImageCard from './ImageCard';
import { Colors } from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
  },
  phone: {
    position: 'relative',
    backgroundSize: 'cover',
    height: 600,
    width: 300,
    paddingTop: 18,
    paddingLeft: 20,
    paddingRight: 19,
    paddingBottom: 17,
    display: 'flex',
    flexDirection: 'column'
  },
  iphoneImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 300,
    height: 600
  },
  appBar: {
    width: '100%',
    height: 50,
    backgroundColor: Colors.white,
    borderRadius: '20px 20px 0 0',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center'
  },
  appBarText: {
    flexGrow: 1,
    marginLeft: 4
  },
  appBarImage: {
    height: 35,
    marginLeft: 4
  },
  phoneContent: {
    flexGrow: 1,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    position: 'relative',
    zIndex: 10,
    overFlowX: 'hidden',
    borderRadius: '0 0 20px 20px',
    width: '100%',
    height: '100%',
    maxHeight: '52.2rem',
    overflowY: 'scroll'
  },
  horizontalRow: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    width: '100%',
    marginBottom: 10
  }
}));

function Emulator(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const [appBarData, setAppBarData] = useState(props.appBarData);

  useEffect(() => {
    setAppBarData(props.appBarData);
  }, [props.appBarData]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleRearrange = (itemRow, dropRow) => {
    props.handleRearrange(itemRow, dropRow);
  };

  return (
    <div className={styles.emulator}>
      <div className={styles.phone}>
        {appBarData.type === 'business' ? (
          <div className={styles.appBar}>
            {props.businessPhoto
              ? <img src={props.businessPhoto} alt="business" className={styles.appBarImage}/>
              : null}
            <span className={styles.appBarText}>
              {props.businessName}
            </span>
            <Menu className={styles.menuIcon} />
          </div>
        ) : appBarData.type === 'location' ? (
          <div className={styles.appBar}>
                <span className={styles.appBarText}>
                  {props.businessName}
                </span>
            <Menu className={styles.menuIcon} />
          </div>
        ) : (
          <div className={styles.appBar}>
            {appBarData.customImageUrl
              ? <img src={appBarData.customImageUrl} alt="custom" className={styles.appBarImage}/>
              : null}
            <span className={styles.appBarText}>
                  {!appBarData.customTitle
                    ? 'Custom Title'
                    : appBarData.customTitle}
                </span>
            <Menu className={styles.menuIcon} />
          </div>
        )}

        <div className={classes.phoneContent}>
          {data.length > 0
            ? data.map((array, index) => {
              const item = array.item;
              const cardId = 'Card ' + (index + 1);

              switch (item.type) {
                case 'info':
                  return (
                    <div key={index}>
                      {props.editingMode ? (
                        <div style={{ height: '1.5rem' }} />
                      ) : (
                        <DragZone row={index} />
                      )}
                      <InfoCard
                        image={item.info.image}
                        title={item.info.title}
                        description={item.info.description}
                        ctaOne={item.info.ctaOne}
                        ctaTwo={item.info.ctaTwo}
                        active={props.activeRow === index}
                        onClick={() => {
                          props.setActiveRow(index);
                        }}
                        cardId={cardId}
                        handleDrop={dropRow =>
                          handleRearrange(index, dropRow)
                        }
                      />
                    </div>
                  );
                case 'image':
                  return (
                    <div key={index}>
                      {props.editingMode ? (
                        <div style={{ height: '1.5rem' }} />
                      ) : (
                        <DragZone row={index} />
                      )}
                      <ImageCard
                        image={item.info.image}
                        title={item.info.title}
                        cta={item.info.cta}
                        active={props.activeRow === index}
                        onClick={() => {
                          props.setActiveRow(index);
                        }}
                        cardId={cardId}
                        handleDrop={dropRow =>
                          handleRearrange(index, dropRow)
                        }
                      />
                    </div>
                  );
                default:
                  return (
                    <div key={index} >
                      <InfoCard />
                    </div>
                  );
              }
            })
            : null}
          {data.length < 2 && !props.disableEdits && !props.editingMode ? (
            <div
              style={{
                position: 'relative',
                zIndex: 2,
                marginTop: 10,
                width: '100%'
              }}
            >
              <Dropzone row={data.length} column={0} />
            </div>
          ) : null}
        </div>

        <Iphone className={styles.iphoneImg} />
      </div>
    </div>
  );
}

Emulator.propTypes = {
  data: PropTypes.array.isRequired,
  activeRow: PropTypes.number.isRequired,
  activeColumn: PropTypes.number,
  setActiveRow: PropTypes.func.isRequired,
  setActiveColumn: PropTypes.func,
  disableEdits: PropTypes.bool,
  editingMode: PropTypes.bool.isRequired
};

export default Emulator;
