import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InfoBuilder from './InfoBuilder';
import ImageBuilder from './ImageBuilder';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}));

function Builders(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.data);
  const [activeRow, setActiveRow] = useState(props.activeRow);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setActiveRow(props.activeRow);
  }, [props.activeRow]);

  return (
    <div className={classes.root}>
      {activeRow < 6 ? (
        data.length > 0 && data[activeRow].item.type === 'info' ? (
          <InfoBuilder
            item={data[activeRow].item.info}
            onUpdate={item => {
              props.handleUpdateItem(item);
            }}
            onDelete={props.handleDeleteItem}
            cardId={activeRow + 1}
            editingMode={props.editingMode}
          />
        ) : data.length > 0 && data[activeRow].item.type === 'image' ? (
          <ImageBuilder
            item={data[activeRow].item.info}
            onUpdate={item => {
              props.handleUpdateItem(item);
            }}
            onDelete={props.handleDeleteItem}
            cardId={activeRow + 1}
            editingMode={props.editingMode}
          />
        ) : (
          <div />
        )
      ) : (
        <div />
      )}
    </div>
  );
}

Builders.propTypes = {
  data: PropTypes.array.isRequired,
  activeRow: PropTypes.number.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  editingMode: PropTypes.bool.isRequired
};

export default Builders;
