import React from "react";
import styles from './styles.module.scss';
import CustomInput from '../UI/Input/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomCheckbox from '../UI/CheckBox/CheckBox';
import Button from '../UI/Button/Button';

const SignUpForm = (props) => {
  return (
    <form className={styles.form} noValidate>
      <div className={styles.formField}>
        <CustomInput
          value={props.data.firstName}
          label="First name"
          fullWidth={true}
          onChange={(e) => props.onChange('firstName', e)}
          onBlur={(e) => props.onBlur('firstName', e)}
          id="firstName"
          error={!!(!props.data.firstName && props.touched.firstName)}
        />
      </div>
      <div className={styles.formField}>
        <CustomInput
          value={props.data.lastName}
          label="Last name"
          fullWidth={true}
          onChange={(e) => props.onChange('lastName', e)}
          onBlur={(e) => props.onBlur('lastName', e)}
          id="lastName"
          error={!props.data.lastName && props.touched.lastName}
        />
      </div>
      <div className={styles.formField}>
        <CustomInput
          value={props.data.business}
          label="Business name"
          fullWidth={true}
          onChange={(e) => props.onChange('business', e)}
          onBlur={(e) => props.onBlur('business', e)}
          id="business"
          error={!props.data.business && props.touched.business}
        />
      </div>
      <div className={styles.formField}>
        <CustomInput
          value={props.data.email}
          label="Email"
          type="email"
          fullWidth={true}
          onChange={(e) => props.onChange('email', e)}
          onBlur={(e) => props.onBlur('email', e)}
          id="email"
          autoComplete="new-password"
          error={!props.emailValid && props.touched.email}
        />
      </div>
      <div className={styles.formField}>
        <CustomInput
          value={props.data.phoneNumber}
          label="Mobile phone"
          fullWidth={true}
          onChange={(e) => props.onChange('phoneNumber', e)}
          id="phone"
          onBlur={(e) => props.onBlur('phoneNumber', e)}
          error={!props.phoneValid && props.touched.phoneNumber}
          helperText="The phone number must have a country code (e. g. +14111000111)"
        />
      </div>
      <div className={styles.formField}>
        <FormControlLabel
          label="I agree to the Terms and Conditions"
          classes={{
            root: styles.formControlLabel
          }}
          onChange={(e) => {props.setAgree(e.target.checked)}}
          control={<CustomCheckbox checked={props.agree} />}
        />
      </div>
      <Button
        title="Send"
        background="primary"
        onClick={props.sendMessage}
        size="medium"
        classProp={styles.submitButton}
        disabled={!props.formValid}
      />
    </form>
  );
};

export default SignUpForm;
