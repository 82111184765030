import { Colors } from '../../constants/colors';

export const createNewItem = function(type) {
  switch (type) {
    case 'info':
      return {
        type,
        info: {
          image: {
            url: '',
            type: '',
            info: {}
          },
          title: '',
          description: { ops: [] },
          ctaOne: {
            type: 'redirect',
            name: '',
            link: ''
          },
          ctaTwo: {
            type: 'none',
            name: '',
            link: ''
          }
        }
      };
    case 'image':
      return {
        type,
        info: {
          image: {
            url: '',
            type: '',
            info: {}
          },
          title: '',
          cta: {
            link: '',
            name: '',
            type: 'none'
          }
        }
      };
    case 'video':
      return {
        type,
        info: {
          type: null,
          code: '',
          title: ''
        }
      };
    case 'button':
      return {
        type,
        info: {
          cta: '',
          link: '',
          color: Colors.primary,
          text: Colors.white
        }
      };
    case 'social':
      return {
        type,
        info: {
          facebook: '',
          twitter: '',
          linkedin: '',
          instagram: ''
        }
      };
    case 'form':
      return {
        type,
        info: {
          title: '',
          fields: ['']
        }
      };
    case 'poll':
      return {
        type,
        info: {
          question: '',
          answers: []
        }
      };
    case 'carousel':
      return {
        type,
        info: {
          images: [
            {
              image: {
                url: '',
                type: '',
                info: {}
              },
              description: ''
            }
          ],
          ctaOne: {
            type: 'redirect',
            name: '',
            link: ''
          },
          ctaTwo: {
            type: 'none',
            name: '',
            link: ''
          }
        }
      };
    default:
      return null;
  }
};
