import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import TemplateCard from './TemplateCard';
import InfoIcon from '../../assets/icons/info_icon.svg';
import ImageIcon from '../../assets/icons/image_icon.svg';
import VideoIcon from '../../assets/icons/video_icon.svg';
import ButtonIcon from '../../assets/icons/button_icon.svg';
import SocialIcon from '../../assets/icons/social_icon.svg';
import FormIcon from '../../assets/icons/form_icon.svg';
import PollIcon from '../../assets/icons/poll_icon.svg';

function Components({ handleTemplateDrop, editingMode, selectedCards, firstSelectedCardType }) {

  return (
      <>
        <div className={styles.cardItem}>
          <TemplateCard
            name="info"
            icon={<InfoIcon />}
            description="Add a card with an image, quick information, and a call to action button"
            title="Information Card"
            handleDrop={handleTemplateDrop}
            dropId="advanced"
            editingMode={selectedCards >= 2 || firstSelectedCardType === "info"}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="image"
            icon={<ImageIcon />}
            description="Add an image card to display different products"
            title="Image Card"
            handleDrop={handleTemplateDrop}
            dropId="advanced"
            editingMode={selectedCards >= 2  || firstSelectedCardType === "image"}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="video"
            icon={<VideoIcon />}
            description="Embed a video from Youtube or Vimeo to play on this campaign"
            title="Video Card"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="button"
            icon={<ButtonIcon />}
            description="Add a clickable button for the recipent to engage with"
            title="Button"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="social"
            icon={<SocialIcon />}
            description="Add a social media bar to link to your favorite sites"
            title="Social Media"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="form"
            icon={<FormIcon />}
            description="Create a small form that recipients can leave responses."
            title="Forms"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="poll"
            icon={<PollIcon />}
            description="Take and display a current poll result"
            title="Poll"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
        <div className={styles.cardItem}>
          <TemplateCard
            name="carousel"
            icon={<ImageIcon />}
            description="Scrollable image carousel."
            title="Image Carousel"
            handleDrop={handleTemplateDrop}
            dropId="template"
            editingMode={true}
          />
        </div>
      </>
  );
}

Components.propTypes = {
  handleTemplateDrop: PropTypes.func.isRequired,
  editingMode: PropTypes.bool.isRequired
};

export default Components;
