import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import BackgroundImage from "gatsby-background-image";
import QRCode from "qrcode.react";
import styles from "./try-now.module.scss";
import {
  createCampaign,
  getUnsplash,
  sendCampaignTextMessage,
  createNewAccount,
} from "../state/actions/mainActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SEO from "../components/SEO";
import Layout from "../components/Layout/Layout";
import Emulator from "../components/Builder/Emulator";
import { createNewItem } from "../components/Builder/Item";
import Components from "../components/Builder/Components";
import Builders from "../components/Builder/Builders";
import Title from "../components/UI/Title/Title";
import Button from "../components/UI/Button/Button";
import WideBackground from "../components/UI/WideBackground/WideBackground";
import CustomInput from "../components/UI/Input/Input";
import CustomCheckbox from "../components/UI/CheckBox/CheckBox";
import PhoneIcon from "../assets/images/phone_mob.svg";
import { Colors } from "../constants/colors";
import { useSiteImages } from "../hooks/useAllImages";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SignUpForm from "../components/SignUpForm/SignUpForm";
import SignUpPasswordForm from "../components/SignUpPasswordForm/SignUpPasswordForm";

const PASSWORD_PATTERN = new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,20})");
const EMAIL_PATTERN = new RegExp(
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
);
const PHONE_NUMBER_PATTERN = new RegExp(
  "\\(?\\+[0-9]{1,3}\\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\\w{1,10}\\s?\\d{1,6})?"
);

const imagesForImport = ["bg_shapes"];

const appBarData = {
  type: "location",
  customImageUrl: "",
  customTitle: "",
};

const editingMode = false;

const TryPage = (props) => {
  const dispatch = useDispatch();
  const images = useSiteImages(imagesForImport);
  const [activeRow, setActiveRow] = useState(10);
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  const [campaignLink, setCampaignLink] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    business: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    business: false,
    email: false,
    phoneNumber: false,
    password: false,
  });

  const [agree, setAgree] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);

  const handleChangeUserData = (key, e) => {
    setUserData({
      ...userData,
      [key]: e.target.value,
    });
  };

  const handleOnBlurInput = (key, e) => {
    if (touched[key]) return;

    setTouched({
      ...touched,
      [key]: true,
    });
  };

  const handleCreateCampaign = () => {
    dispatch(
      createCampaign({
        cardOne: data[0].item,
        cardTwo: data[1].item,
      })
    ).then((res) => {
      setCampaignLink(res.link);
      handleNextStep();
    });
    handleNextStep();
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    dispatch(createNewAccount(userData)).then(() => {
      navigate("https://account.tappshare.io/");
    });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    dispatch(
      sendCampaignTextMessage({
        phoneNumber: userData.phoneNumber,
        link: campaignLink,
      })
    ).then((res) => {
      handleNextStep();
    });
    handleNextStep();
  };

  const handleResendSendMessage = (e) => {
    e.preventDefault();
    dispatch(
      sendCampaignTextMessage({
        phoneNumber: userData.phoneNumber,
        link: campaignLink,
      })
    );
  };

  const rearrangeCards = (from, to) => {
    const newDataArray = [...data];
    const move = newDataArray.splice(from, 1)[0];
    newDataArray.splice(to, 0, move);
    setData(newDataArray);
  };

  const handleTemplateDrop = (type, row) => {
    const array = [...data];
    const item = createNewItem(type);
    array[row] = {
      item: item,
      row: row,
    };

    setData(array);
    setActiveRow(row);
  };

  const handleUpdateItem = (item) => {
    var newData = [...data];
    newData[activeRow].item.info = item;
    setData(newData);
  };

  const handleDeleteItem = () => {
    const newObjects = [...data];
    newObjects.splice(activeRow, 1);
    setActiveRow(10);
    setData(newObjects);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
    scrollToTop();
  };

  const handleNextStep = () => {
    setStep(step + 1);
    scrollToTop();
  };

  const scrollToTop = () => {
    if (typeof window === "undefined" || !window.document) {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const checkPasswordIsValid = () => {
    const isValid = !!PASSWORD_PATTERN.exec(userData.password);
    setPasswordValid(isValid);
    return isValid;
  };

  const checkEmailIsValid = () => {
    const isValid = !!EMAIL_PATTERN.exec(userData.email);
    setEmailValid(isValid);
    return isValid;
  };

  const checkPhoneIsValid = () => {
    const isValid = !!PHONE_NUMBER_PATTERN.exec(userData.phoneNumber);
    setPhoneValid(isValid);
    return isValid;
  };

  useEffect(() => {
    checkPasswordIsValid();
    const emailValid = checkEmailIsValid();
    const phoneValid = checkPhoneIsValid();

    if (
      emailValid &&
      phoneValid &&
      agree &&
      userData.firstName &&
      userData.lastName &&
      userData.business
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [userData, touched, agree]);

  useEffect(() => {
    dispatch(getUnsplash({ query: "business" }));
  }, [dispatch]);

  return (
    <Layout>
      <SEO title="Try Now" />
      <DndProvider backend={HTML5Backend}>
        <div className={styles.desktop}>
          {step === 1 ? (
            <div className={styles.stepOne}>
              <Title
                title="Drag content cards to create engaging campaigns."
                variant="h3"
                classProp={styles.title}
              />
              <div className={styles.overview}>
                <div className={styles.cards}>
                  <Components
                    handleTemplateDrop={handleTemplateDrop}
                    editingMode={editingMode}
                    selectedCards={data.length}
                    firstSelectedCardType={
                      data.length > 0 ? data[0].item.type : ""
                    }
                  />
                </div>
                <div>
                  <Emulator
                    data={data}
                    appBarData={appBarData}
                    businessName={userData.business}
                    businessPhoto={props.businessPhotoUrl}
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                    handleRearrange={rearrangeCards}
                    editingMode={editingMode}
                  />
                </div>
                <div
                  style={{
                    width: "37.9rem",
                  }}
                >
                  <Builders
                    data={data}
                    activeRow={activeRow}
                    handleUpdateItem={handleUpdateItem}
                    handleDeleteItem={handleDeleteItem}
                    editingMode={editingMode}
                  />
                </div>
                <WideBackground color="aliceblue-2" />
              </div>
              <Button
                title="Next"
                background="primary"
                onClick={handleCreateCampaign}
                size="medium"
                classProp={styles.stepButton}
                disabled={data.length < 2}
              />
            </div>
          ) : step === 2 ? (
            <div className={styles.stepTwo}>
              <div className={styles.container}>
                <Title
                  title="Now we just need to know where to send the campaign."
                  variant="h3"
                  classProp={clsx(styles.title, styles.stepTwoTitle)}
                />
                <div className={styles.content}>
                  <SignUpForm
                    data={userData}
                    touched={touched}
                    onChange={handleChangeUserData}
                    onBlur={handleOnBlurInput}
                    setAgree={setAgree}
                    agree={agree}
                    emailValid={emailValid}
                    phoneValid={phoneValid}
                    formValid={formValid}
                    sendMessage={handleSendMessage}
                  />
                  <div className={styles.emulator}>
                    <Title
                      title="Mobile Preview"
                      variant="h5"
                      weight="extra-bold"
                      classProp={styles.smallTitle}
                    />
                    <Emulator
                      data={data}
                      appBarData={appBarData}
                      businessName={userData.business}
                      businessPhoto={props.businessPhotoUrl}
                      activeRow={activeRow}
                      setActiveRow={setActiveRow}
                      handleRearrange={rearrangeCards}
                      editingMode={editingMode}
                    />
                  </div>
                  <div className={styles.campaignQR}>
                    <Title
                      title="Campaign QR for Offline use"
                      variant="h5"
                      weight="extra-bold"
                      classProp={styles.smallTitle}
                    />
                    {campaignLink && (
                      <QRCode
                        value={campaignLink}
                        renderAs="svg"
                        fgColor={Colors.primary}
                      />
                    )}
                    <p>Download or Print</p>
                  </div>
                </div>
              </div>
              <Button
                title="Back"
                background="primary"
                onClick={handlePreviousStep}
                size="medium"
                classProp={styles.stepButton}
              />
              <WideBackground color="aliceblue-2" />
            </div>
          ) : step === 3 ? (
            <div className={styles.stepThree}>
              <div className={styles.container}>
                <div className={styles.left}>
                  <PhoneIcon className={styles.icon} />
                  <div className={styles.text}>
                    <div>
                      <Title
                        title="You did it!"
                        variant="h4"
                        color="primary"
                        weight="extra-bold"
                      />
                      <p className={styles.info}>
                        Check your text <br />
                        messages
                      </p>
                    </div>
                    <div className={styles.didntGetText}>
                      Didn't get the text.
                      <span onClick={handleResendSendMessage}>Click here.</span>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <Title
                    title="Start free trial, today."
                    variant="h4"
                    color="white"
                    weight="extra-bold"
                    classProp={styles.trialTitle}
                  />
                  <Title
                    title="No credit card needed."
                    variant="h4"
                    color="white"
                    weight="extra-bold"
                    classProp={styles.trialTitle}
                  />
                  <p className={styles.info}>You're just one step away!</p>
                  <Button
                    title="7-DAY FREE TRIAL"
                    background="white"
                    textColor="primary"
                    classProp={styles.trialBtn}
                    onClick={handleNextStep}
                  />
                  <BackgroundImage
                    Tag="div"
                    className={styles.bg}
                    fluid={images.bg_shapes}
                    backgroundColor={`#fff`}
                  />
                </div>
              </div>
              <WideBackground color="aliceblue-2" />
            </div>
          ) : step === 4 ? (
            <div className={styles.stepFour}>
              <SignUpPasswordForm
                onChange={handleChangeUserData}
                onBlur={handleOnBlurInput}
                valid={passwordValid}
                touched={touched.password}
                onSubmit={handleCreateAccount}
                value={userData.password}
              />
              <WideBackground color="aliceblue-2" />
            </div>
          ) : null}
        </div>
        <div className={styles.mobile}>
          {step === 1 ? (
            <div className={styles.stepOne}>
              <div className={styles.container}>
                <Title
                  title="Start your 7-day free trial, today. No credit card needed. You're just one step away!"
                  variant="h3"
                  classProp={clsx(styles.title, styles.stepTwoTitle)}
                />
                <SignUpForm
                  data={userData}
                  touched={touched}
                  onChange={handleChangeUserData}
                  onBlur={handleOnBlurInput}
                  setAgree={setAgree}
                  agree={agree}
                  emailValid={emailValid}
                  phoneValid={phoneValid}
                  formValid={formValid}
                  sendMessage={handleSendMessage}
                />
              </div>
              <WideBackground color="aliceblue-2" />
            </div>
          ) : step === 2 ? (
            <div className={styles.stepTwo}>
              <SignUpPasswordForm
                onChange={handleChangeUserData}
                onBlur={handleOnBlurInput}
                valid={passwordValid}
                touched={touched.password}
                onSubmit={handleCreateAccount}
                value={userData.password}
              />
              <WideBackground color="aliceblue-2" />
            </div>
          ) : null}
        </div>
      </DndProvider>
    </Layout>
  );
};

export default TryPage;
