import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Colors } from '../../constants/colors';
import CustomInput from '../UI/Input/Input';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  autocomplete: {

  },
  textField: {
    flexGrow: 1,
    [`& fieldset`]: {
      borderRadius: 4
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.primary
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      padding: '16.5px 14px !important',
      color: Colors.black,
      fontSize: 14
    },
    '& .MuiInputLabel-outlined': {
      color: Colors.black
    },
    '& .MuiOutlinedInput-root': {
      padding: '0 !important'
    },
    '& .MuiInputBase-input::placeholder': {
      color: Colors.primaryBlack,
      opacity: 1
    },
  },
  popper: {
    '& li': {
      color: Colors.black,
      fontSize: 14
    }
  }
});

const suggestedOptions = [
  'Learn More',
  'Book Now',
  'Apply Now',
  'Contact Us',
  'Donate Now',
  'Download',
  'Request Time',
  'See Menu',
  'Shop Now',
  'Sign Up'
];

function CTATextField(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        freeSolo
        multiple={props.multiple}
        value={props.value}
        inputValue={props.value}
        onInputChange={(e, newInputValue) => props.onChange(newInputValue)}
        onChange={(e, newValue) => props.onChange(newValue)}
        disableClearable
        options={suggestedOptions.map(option => option)}
        className={classes.autocomplete}
        classes={{
          popper: classes.popper
        }}
        renderInput={params => (
          <CustomInput
            {...params}
            InputProps={{ ...params.InputProps, type: 'search' }}
            fullWidth={true}
            label={
              props.placeholder
                ? props.placeholder
                : 'Button Text'
            }
          />
        )}
      />
    </div>
  );
}

CTATextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

export default CTATextField;
