import React, { useState } from "react";
import styles from "./styles.module.scss";
import Title from "../UI/Title/Title";
import clsx from "clsx";
import CustomInput from "../UI/Input/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "../UI/Button/Button";

const SignUpPasswordForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form className={styles.form} noValidate>
      <Title
        title="The only thing left to do is create a password for your TappShare login!"
        variant="h3"
        classProp={styles.title}
      />
      <div className={styles.formField}>
        <CustomInput
          value={props.value}
          label="Password"
          type={showPassword ? "text" : "password"}
          icon={showPassword ? <Visibility /> : <VisibilityOff />}
          onIconClick={handleClickShowPassword}
          fullWidth={true}
          onChange={(e) => props.onChange("password", e)}
          id="password"
          autoComplete="new-password"
          helperText="Must have at least 6 characters, at least one number and at least one special character"
          onBlur={(e) => props.onBlur("password", e)}
          error={!props.valid && props.touched}
        />
      </div>
      <Button
        title="Create account"
        background="primary"
        onClick={props.onSubmit}
        size="medium"
        classProp={styles.submitButton}
        disabled={!props.valid}
      />
    </form>
  );
};

export default SignUpPasswordForm;
