import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { useDrag } from 'react-dnd';
import clsx from 'clsx';

function TemplateCard(props) {
  const [{ opacity }, dragRef] = useDrag({
    item: { type: props.dropId, name: props.name },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.7 : 1
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && !props.editingMode) {
        props.handleDrop(item.name, dropResult.row, dropResult.column);
      }
    }
  });

  return (
    <div ref={props.editingMode ? undefined : dragRef} className={clsx(styles.templateCard, props.editingMode ? styles.inActive : '')} >
      <div className={styles.top}>
        <div className={styles.icon}>{props.icon}</div>
      </div>
      <div className={styles.bottom}>
        <p className={styles.title}>{props.title}</p>
        <p className={styles.description}>{props.description}</p>
      </div>
    </div>
  );
}

TemplateCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleDrop: PropTypes.func.isRequired,
  dropId: PropTypes.string.isRequired,
  editingMode: PropTypes.bool.isRequired
};

export default TemplateCard;
