import { useStaticQuery, graphql } from "gatsby"
export const useSiteImages = (imagesNames) => {

  const imagesData = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          edges {
            node {
              id
              fluid(quality: 100) {
                src
                srcSet
                base64
                aspectRatio
                originalImg
                sizes
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    `);

  let images = {};

  imagesData.allImageSharp.edges.forEach(img => {
    const pathAsArray = img.node.fluid.src.split('/');
    const fileName = pathAsArray[pathAsArray.length - 1].split('.')[0];

    imagesNames.forEach(name => {
      if (fileName === name) {
        images = {
          ...images,
          [name]: img.node.fluid
        };
      }
    });
  });

  return images;
}
