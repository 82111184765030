import React from 'react';
import styles from './styles.module.scss';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import DefaultImage from '../../assets/images/default-background.svg';
import { useDrag } from 'react-dnd';
import clsx from 'clsx';

function ImageCard(props) {
  const [{ opacity }, dragRef] = useDrag({
    item: { type: 'card', name: 'Name' },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.7 : 1
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        props.handleDrop(dropResult.row);
      }
    }
  });

  return (
    <div ref={dragRef} style={{ opacity: opacity }} className={styles.imageCard}>
      <div
        className={clsx(styles.card, props.active ? styles.active : '')}
        onClick={props.onClick}
      >
        <div className={styles.imageBox}>
          <div
            className={styles.imageHolder}
             style={{
               backgroundImage:  props.image.url ? `url('${props.image.url}')` : 'none'
             }}
          >
            {!props.image.url && <DefaultImage className={styles.image} />}
          </div>
        </div>
        <div className={styles.bottom}>
          {props.title && <span className={styles.titleText}>{props.title}</span>}
          {props.cta.type === 'redirect' ? (
            <Button
              color="primary"
              variant="contained"
              className={styles.ctaButton}
              disableElevation
            >
              <Typography className={styles.buttonText}>
                {props.cta.name}
              </Typography>
            </Button>
          ) : props.cta.type === 'event' ? (
            <Typography className={styles.addToCalendarText}>
              Add to Calendar
            </Typography>
          ) : (
            <div />
          )}
        </div>
        <span className={styles.info}>
            {props.cardId}
        </span>
      </div>
    </div>
  );
}

ImageCard.propTypes = {};

export default ImageCard;
