import { Colors } from "./colors";

export const quillStyles = {
  '& .ql-editor>*': {
    fontSize: '1.4rem'
  },
  '& .ql-toolbar.ql-snow': {
    borderColor: Colors.primary,
    borderTopRightRadius: '0.6rem',
    borderTopLeftRadius: '0.6rem'
  },
  '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
    borderColor: Colors.primary,
    color: Colors.black,
    borderBottomRightRadius: '0.6rem',
    borderBottomLeftRadius: '0.6rem'
  },
  '& .ql-editor.ql-blank::before': {
    fontStyle: 'normal',
    color: Colors.black,
    fontSize: '1.4rem'
  }
};
