import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '../../assets/icons/add_icon.svg';
import { Colors } from '../../constants/colors';
import { useDrop } from 'react-dnd';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '15rem',
    borderStyle: 'dashed',
    borderWidth: '0.1rem',
    borderRadius: '1rem',
    marginBottom: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.white
  },
  icon: {
    width: '6rem',
    height: '6rem',
    marginBottom: '0.8rem'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: Colors.primary
  }
}));

function Dropzone(props) {
  const classes = useStyles();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: props.restricted ? 'advanced' : ['template', 'advanced'],
    drop: () => (
      { name: 'Dropzone', row: props.row, column: props.column }
    ),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;
  const notAllowed = !canDrop && isOver;
  let borderColor = Colors.primary;
  let color = Colors.primary;
  if (isActive) {
    borderColor = Colors.success;
    color = Colors.success;
  } else if (notAllowed) {
    borderColor = Colors.error;
    color = Colors.error;
  }

  return (
    <div ref={drop} className={classes.root} style={{ borderColor }}>
      <AddIcon  className={classes.icon} style={{ color }} />
      <span className={classes.label}>
        Add Another Card
      </span>
    </div>
  );
}

Dropzone.propTypes = {
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
  restricted: PropTypes.bool
};

export default Dropzone;
