import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';

// color: primary | aliceblue
// classProp: class

const WideBackground = ({ color, classProp }) => {
  return (
    <div
      className={clsx(
        styles.background,
        color === 'primary' ? styles.primary : '',
        color === 'aliceblue' ? styles.aliceblue : '',
        color === 'aliceblue-2' ? styles.aliceblue2 : '',
        classProp ? classProp : ''
      )}
    />
  )
};

WideBackground.propTypes = {
  color: PropTypes.string,
  classProp: PropTypes.string,
  element: PropTypes.node,
};

export default WideBackground;

