import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import DefaultImage from '../../assets/images/default-background.svg';
import CalendarIcon from '../../assets/icons/calendar_icon.svg';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useDrag } from 'react-dnd';
import styles from './styles.module.scss';
import clsx from 'clsx';

function InfoCard(props) {
  const [description, setDescription] = useState('');

  const [{ opacity }, dragRef] = useDrag({
    item: { type: 'card', name: 'Name' },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.7 : 1
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        props.handleDrop(dropResult.row);
      }
    }
  });

  useEffect(() => {
    if (props.description) {
      var cfg = {};
      var converter = new QuillDeltaToHtmlConverter(props.description.ops, cfg);
      setDescription(converter.convert());
    }
  }, [props.description]);

  return (
    <>
      <div ref={dragRef} style={{ opacity: opacity }} className={clsx(styles.infoCard, props.active ? styles.active : '')}>
        <div
          className={styles.card}
          onClick={props.onClick}
        >
          <div className={styles.imageBox}>
            <div
              className={styles.imageHolder}
              style={{
                backgroundImage:  props.image.url ? `url('${props.image.url}')` : 'none'
              }}
            >
              {!props.image.url && <DefaultImage className={styles.image} />}
            </div>
          </div>
          <div className={styles.description}>
            {props.title && <span className={styles.title}>{props.title}</span>}
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: description
              }}
            />
            <div className={styles.actions}>

              {props.ctaOne.type === 'redirect' ? (
                <Button
                  color="primary"
                  variant="contained"
                  className={styles.ctaButton}
                  disableElevation
                >
                  <Typography className={styles.buttonText}>
                    {props.ctaOne.name}
                  </Typography>
                </Button>
              ) : props.ctaOne.type === 'event' ? (
                <div className={styles.addToCalendar}>
                  <span>Add to Calendar</span>
                  <CalendarIcon className={styles.icon} />
                </div>
              ) : (
                <div />
              )}

              {props.ctaTwo.type === 'redirect' ? (
                <Button
                  color="primary"
                  variant="contained"
                  className={styles.ctaButton}
                  disableElevation
                >
                  <Typography className={styles.buttonText}>
                    {props.ctaTwo.name}
                  </Typography>
                </Button>
              ) : props.ctaTwo.type === 'event' ? (
                <div className={styles.addToCalendar}>
                 <span>Add to Calendar</span>
                  <CalendarIcon className={styles.icon} />
                </div>
              ) : (
                <div />
              )}



            </div>
          </div>
          <span className={styles.info}>
            {props.cardId}
          </span>

        </div>
      </div>
    </>
  );
}

InfoCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaOne: PropTypes.object,
  ctaTwo: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  cardId: PropTypes.string
};

export default InfoCard;
